footer {
  padding:8rem 0 1rem 0;
  margin:3rem 0 1rem 0;
  text-align: center;
  color: #575757;

  a {
    text-decoration: none;
  }

  a:visited {
    color: rgb(255, 255, 255);
  }
  
  a:hover{
    color: rgb(255, 199, 131);
  }
}
