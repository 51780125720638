*,
*::after,
*::before {
	box-sizing: border-box;
}

:root {
	font-size: 16px;
	--color-text: #c27e73;
	--color-bg: #141418;
	--color-link: #a8dbe0;
	--color-link-hover: #fff;
	--color-title: #99dde2;
	--color-title-alt: #e2d2c3;
	--color-border: rgb(91 91 91 / 44%);
	--color-menu-item: #393634;
	--color-menu-item-alt: #e0d2c4;
	--color-menu-item-hover: #efafa5;
	--color-menu-item-desc: #f1f1f1;
	--color-desc-cont: #e2d2c3;
	--color-desc: #656569;
}

/* html, body {
	height: 100%;
	overflow: hidden;
} */

body {
	margin: 0;
	color: var(--color-text);
	background-color: var(--color-bg);
	/* font-family: "acumin-variable", sans-serif;*/
	/* font-family: 'AlphaLyrae', sans-serif; */

	/* font-variation-settings: "slnt" 0, "wdth" 98, "wght" 400; */
	text-transform: uppercase;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

/* https://vegaprotocol.github.io/alpha-lyrae/ */

/* @font-face {
	font-family: 'AlphaLyrae';
	src: url('../fonts/AlphaLyrae-Medium.woff2') format('woff2'),
		url('../fonts/AlphaLyrae-Medium.woff') format('woff');
	font-style: normal;
} */

@font-feature-values 'AlphaLyrae' {
	@styleset {
		ss01: 1;
		ss02: 2;
		ss03: 3;
		ss04: 4;
		ss05: 5
	}
}

.ImageTilesMenu__container {

	/* Page Loader */
	.js .loading::before,
	.js .loading::after {
		content: '';
		position: fixed;
		z-index: 1000;
	}

	.js .loading::before {
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: var(--color-bg);
	}

	.js .loading::after {
		top: 50%;
		left: 50%;
		width: 60px;
		height: 60px;
		margin: -30px 0 0 -30px;
		border-radius: 50%;
		opacity: 0.4;
		background: var(--color-link);
		animation: loaderAnim 0.7s linear infinite alternate forwards;

	}

	@keyframes loaderAnim {
		to {
			opacity: 1;
			transform: scale3d(0.5, 0.5, 1);
		}
	}

	a {
		text-decoration: none;
		color: var(--color-link);
		outline: none;
		cursor: pointer;
	}

	a:hover {
		color: var(--color-link-hover);
		outline: none;
	}

	/* Better focus styles from https://developer.mozilla.org/en-US/docs/Web/CSS/:focus-visible */
	a:focus {
		/* Provide a fallback style for browsers
	 that don't support :focus-visible */
		outline: none;
		background: lightgrey;
	}

	a:focus:not(:focus-visible) {
		/* Remove the focus indicator on mouse-focus for browsers
	 that do support :focus-visible */
		background: transparent;
	}

	a:focus-visible {
		/* Draw a very noticeable focus style for
	 keyboard-focus on browsers that do support
	 :focus-visible */
		outline: 2px solid red;
		background: transparent;
	}

	.unbutton {
		background: none;
		border: 0;
		padding: 0;
		margin: 0;
		font: inherit;
		cursor: pointer;
	}

	.unbutton:focus {
		outline: none;
	}

	main {
		/* overflow-y: auto; */
		position: relative;
		display: grid;
		grid-template-areas: 'main';
		width: 100%;
		height: auto;
		/* font-family: 'AlphaLyrae', sans-serif; */
	}

	.frame {
		grid-area: main;
		z-index: 1000;
		position: relative;
		width: 100%;
		padding: 1.5rem;
		display: grid;
		grid-template-columns: 100%;
		grid-template-areas: 'title' 'prev' 'sponsor';
		grid-gap: 0.5rem;
		justify-items: start;
		align-self: start;
		justify-self: start;
		pointer-events: none;
		align-items: center;
	}

	body #cdawrap {
		justify-self: start;
	}

	.frame a {
		pointer-events: auto;
	}

	.frame a:not(.frame__title-back) {
		white-space: nowrap;
		overflow: hidden;
		position: relative;
	}

	.frame a:not(.frame__title-back)::before {
		content: '';
		height: 1px;
		width: 100%;
		background: currentColor;
		position: absolute;
		top: 90%;
		transition: transform 0.3s;
		transform-origin: 0% 50%;
	}

	.frame a:not(.frame__title-back):hover::before {
		transform: scaleX(0);
		transform-origin: 100% 50%;
	}

	.frame__title {
		grid-area: title;
		display: flex;
	}

	.frame__title-main {
		font-size: inherit;
		margin: 0;
		font-weight: inherit;
	}

	.frame__title-back {
		position: relative;
		display: flex;
		align-items: flex-end;
	}

	.frame__title-back span {
		display: none;
	}

	.frame__title-back svg {
		fill: currentColor;
	}

	.frame__prev {
		grid-area: prev;
	}

	.title {
		text-align: center;
		font-family: Anton;
	}

	.menu {
		position: relative;
		grid-area: main;
		/* 	display: flex;  */
		flex-direction: column;
		justify-content: center;
	}

	.menu--hidden {
		pointer-events: none;
	}

	.menu__item:not(:last-child) {
		border-bottom: 1px solid var(--color-border);
	}

	.menu__item-title {
		white-space: nowrap;
		/* font-family: afronaut, serif; */
		/* font-family: 'AlphaLyrae', sans-serif; */

		font-weight: 400;
		text-transform: none;
		font-size: 5vh;
		grid-column: 2;
		color: var(--color-menu-item);
		transform: translateX(50px);
		display: block;
	}

	.menu__item:hover .menu__item-title,
	.menu__item:focus .menu__item-title {
		color: var(--color-menu-item-hover);
	}

	.menu__item .menu__item-title i {
		font-style: normal;
	}

	.menu__item:hover .menu__item-title i,
	.menu__item:focus .menu__item-title i {
		color: var(--color-menu-item-alt);
		transition: color 0.2s ease-out;
	}

	.menu__item-desc {
		opacity: 0;
		line-height: 1;
		max-width: 450px;
		font-weight: 500;
		display: none;
		font-size: clamp(0.5rem, 2.5vh, 1rem);
		color: var(--color-menu-item-desc);
	}

	.thumbgrid-wrap {
		position: relative;
		z-index: -1;
		grid-area: main;
		display: grid;
		pointer-events: none;
	}

	.thumbgrid {
		grid-area: 1 / -1;
		width: 100%;
		display: grid;

		/* 
			grid-template-columns: repeat(100, 1%);

			This property defines the columns of the grid.
			The repeat() function is a convenient way to define multiple columns or rows that have the same size. 
			Here, repeat(100, 1%) means that the grid will have 100 columns, each taking up 1% of the container's width. 
			This effectively divides the container into 100 equal columns.

			grid-template-rows: repeat(100, 1%);

			Similarly, this property defines the rows of the grid. Using repeat(100, 1%) 
			means that the grid will have 100 rows, each taking up 1% of the container's height. 
			This divides the container into 100 equal rows.
		*/
		grid-template-columns: repeat(100, 1%);
		grid-template-rows: repeat(100, 1%);
	}

	.thumbgrid-wrap .thumbgrid__item {
		opacity: 0;
		grid-area: var(--grid-row) / var(--grid-column);
		aspect-ratio: 4 / 3;
		width: 18vw;
	}

	.thumbgrid-wrap .thumbgrid--current .thumbgrid__item {
		opacity: 1;
	}

	.thumbgrid__item:nth-child(1) {
		--bg-pos-x: 0;
		--bg-pos-y: 0;
	}

	.thumbgrid__item:nth-child(2) {
		--bg-pos-x: 100%;
		--bg-pos-y: 0;
	}

	.thumbgrid__item:nth-child(3) {
		--bg-pos-x: 0;
		--bg-pos-y: 100%;
	}

	.thumbgrid__item:nth-child(4) {
		--bg-pos-x: 100%;
		--bg-pos-y: 100%;
	}

	.pos-1 {
        --grid-row: 7;
        --grid-column: 60;
    }
    
    .pos-2 {
        --grid-row: 15;
        --grid-column: 30;
    }
    
    .pos-3 {
        --grid-row: 7;
        --grid-column: 5;
    }
    
    .pos-4 {
        --grid-row: 11;
        --grid-column: 80;
    }
    
    .pos-5 {
        --grid-row: 13;
        --grid-column: 5;
    }
    
    .pos-6 {
        --grid-row: 12;
        --grid-column: 60;
    }
    
    .pos-7 {
        --grid-row: 14;
        --grid-column: 80;
    }
    
    .pos-8 {
        --grid-row: 9;
        --grid-column: 30;
    }
    
    .pos-9 {
        --grid-row: 15;
        --grid-column: 30;
    }
    
    .pos-10 {
        --grid-row: 13;
        --grid-column: 60;
    }
    
    .pos-11 {
        --grid-row: 25;
        --grid-column: 5;
    }
    
    .pos-12 {
        --grid-row: 15;
        --grid-column: 80;
    }
    
    .pos-13 {
        --grid-row: 14;
        --grid-column: 5;
    }
    
    .pos-14 {
        --grid-row: 22;
        --grid-column: 30;
    }
    
    .pos-15 {
        --grid-row: 28;
        --grid-column: 80;
    }
    
    .pos-16 {
        --grid-row: 18;
        --grid-column: 60;
    }
    
    .pos-17 {
        --grid-row: 24;
        --grid-column: 80;
    }
    
    .pos-18 {
        --grid-row: 33;
        --grid-column: 30;
    }
    
    .pos-19 {
        --grid-row: 20;
        --grid-column: 60;
    }
    
    .pos-20 {
        --grid-row: 34;
        --grid-column: 5;
    }
    
    .pos-21 {
        --grid-row: 33;
        --grid-column: 60;
    }
    
    .pos-22 {
        --grid-row: 28;
        --grid-column: 30;
    }
    
    .pos-23 {
        --grid-row: 28;
        --grid-column: 5;
    }
    
    .pos-24 {
        --grid-row: 29;
        --grid-column: 80;
    }
    
    .pos-25 {
        --grid-row: 35;
        --grid-column: 30;
    }
    
    .pos-26 {
        --grid-row: 30;
        --grid-column: 60;
    }
    
    .pos-27 {
        --grid-row: 32;
        --grid-column: 80;
    }
    
    .pos-28 {
        --grid-row: 36;
        --grid-column: 5;
    }
    
    .pos-29 {
        --grid-row: 44;
        --grid-column: 5;
    }
    
    .pos-30 {
        --grid-row: 40;
        --grid-column: 30;
    }
    
    .pos-31 {
        --grid-row: 44;
        --grid-column: 80;
    }
    
    .pos-32 {
        --grid-row: 44;
        --grid-column: 60;
    }
    
    .pos-33 {
        --grid-row: 42;
        --grid-column: 30;
    }
    
    .pos-34 {
        --grid-row: 42;
        --grid-column: 80;
    }
    
    .pos-35 {
        --grid-row: 41;
        --grid-column: 60;
    }
    
    .pos-36 {
        --grid-row: 45;
        --grid-column: 5;
    }
    
    .pos-37 {
        --grid-row: 38;
        --grid-column: 60;
    }
    
    .pos-38 {
        --grid-row: 41;
        --grid-column: 30;
    }
    
    .pos-39 {
        --grid-row: 43;
        --grid-column: 5;
    }
    
    .pos-40 {
        --grid-row: 41;
        --grid-column: 80;
    }
    
    .pos-41 {
        --grid-row: 49;
        --grid-column: 80;
    }
    
    .pos-42 {
        --grid-row: 44;
        --grid-column: 5;
    }
    
    .pos-43 {
        --grid-row: 49;
        --grid-column: 30;
    }
    
    .pos-44 {
        --grid-row: 51;
        --grid-column: 60;
    }
    
    .pos-45 {
        --grid-row: 55;
        --grid-column: 60;
    }
    
    .pos-46 {
        --grid-row: 53;
        --grid-column: 80;
    }
    
    .pos-47 {
        --grid-row: 61;
        --grid-column: 5;
    }
    
    .pos-48 {
        --grid-row: 61;
        --grid-column: 30;
    }
    
    .pos-49 {
        --grid-row: 54;
        --grid-column: 80;
    }
    
    .pos-50 {
        --grid-row: 64;
        --grid-column: 60;
    }
    
    .pos-51 {
        --grid-row: 63;
        --grid-column: 30;
    }
    
    .pos-52 {
        --grid-row: 57;
        --grid-column: 5;
    }
    
    .pos-53 {
        --grid-row: 64;
        --grid-column: 80;
    }
    
    .pos-54 {
        --grid-row: 69;
        --grid-column: 5;
    }
    
    .pos-55 {
        --grid-row: 64;
        --grid-column: 30;
    }
    
    .pos-56 {
        --grid-row: 59;
        --grid-column: 60;
    }
    
    .pos-57 {
        --grid-row: 65;
        --grid-column: 30;
    }
    
    .pos-58 {
        --grid-row: 67;
        --grid-column: 80;
    }
    
    .pos-59 {
        --grid-row: 64;
        --grid-column: 60;
    }
    
    .pos-60 {
        --grid-row: 71;
        --grid-column: 5;
    }
    
    .pos-61 {
        --grid-row: 62;
        --grid-column: 60;
    }
    
    .pos-62 {
        --grid-row: 74;
        --grid-column: 5;
    }
    
    .pos-63 {
        --grid-row: 72;
        --grid-column: 30;
    }
    
    .pos-64 {
        --grid-row: 72;
        --grid-column: 80;
    }
    
    .pos-65 {
        --grid-row: 67;
        --grid-column: 60;
    }
    
    .pos-66 {
        --grid-row: 81;
        --grid-column: 30;
    }
    
    .pos-67 {
        --grid-row: 82;
        --grid-column: 5;
    }
    
    .pos-68 {
        --grid-row: 72;
        --grid-column: 80;
    }
    
    .pos-69 {
        --grid-row: 73;
        --grid-column: 60;
    }
    
    .pos-70 {
        --grid-row: 82;
        --grid-column: 80;
    }
    
    .pos-71 {
        --grid-row: 76;
        --grid-column: 5;
    }
    
    .pos-72 {
        --grid-row: 79;
        --grid-column: 30;
    }
    
    .pos-73 {
        --grid-row: 79;
        --grid-column: 30;
    }
    
    .pos-74 {
        --grid-row: 85;
        --grid-column: 80;
    }
    
    .pos-75 {
        --grid-row: 82;
        --grid-column: 5;
    }
    
    .pos-76 {
        --grid-row: 88;
        --grid-column: 60;
    }
    
    .pos-77 {
        --grid-row: 81;
        --grid-column: 60;
    }
    
    .pos-78 {
        --grid-row: 81;
        --grid-column: 30;
    }
    
    .pos-79 {
        --grid-row: 92;
        --grid-column: 5;
    }
    
    .pos-80 {
        --grid-row: 83;
        --grid-column: 80;
    }
    
    .pos-81 {
        --grid-row: 85;
        --grid-column: 5;
    }
    
    .pos-82 {
        --grid-row: 84;
        --grid-column: 30;
    }
    
    .pos-83 {
        --grid-row: 92;
        --grid-column: 80;
    }
    
    .pos-84 {
        --grid-row: 89;
        --grid-column: 60;
    }
    
    .pos-85 {
        --grid-row: 99;
        --grid-column: 30;
    }
    
    .pos-86 {
        --grid-row: 89;
        --grid-column: 60;
    }
    
    .pos-87 {
        --grid-row: 95;
        --grid-column: 80;
    }
    
    .pos-88 {
        --grid-row: 97;
        --grid-column: 5;
    }
    
    .pos-89 {
        --grid-row: 99;
        --grid-column: 60;
    }
    
    .pos-90 {
        --grid-row: 93;
        --grid-column: 30;
    }
    
    .pos-91 {
        --grid-row: 101;
        --grid-column: 5;
    }
    
    .pos-92 {
        --grid-row: 103;
        --grid-column: 80;
    }
    
    .pos-93 {
        --grid-row: 105;
        --grid-column: 5;
    }
    
    .pos-94 {
        --grid-row: 96;
        --grid-column: 80;
    }
    
    .pos-95 {
        --grid-row: 103;
        --grid-column: 30;
    }
    
    .pos-96 {
        --grid-row: 101;
        --grid-column: 60;
    }
    
    .pos-97 {
        --grid-row: 103;
        --grid-column: 80;
    }
    
    .pos-98 {
        --grid-row: 113;
        --grid-column: 30;
    }
    
    .pos-99 {
        --grid-row: 109;
        --grid-column: 60;
    }
	
	.thumbgrid__item-img {
		width: 100%;
		height: 100%;
		background-size: 200%;
		background-position: var(--bg-pos-x) var(--bg-pos-y);
	}

	.content-wrap {
		grid-area: main;
		display: none;
		pointer-events: none;
	}

	.content {
		pointer-events: none;
		opacity: 0;
		grid-area: 1 / -1;
		max-height: 100vh;
		padding: 8rem 1.5rem;
	}

	.content--current {
		pointer-events: auto;
		opacity: 1;
	}

	.content__title {
		font-family: afronaut, serif;
		font-weight: 400;
		text-transform: none;
		font-size: clamp(2rem, 10vw, 8rem);
		margin: 0;
		line-height: 1.2;
		color: var(--color-title);
		grid-column: 2;
	}

	.content__title i {
		font-style: normal;
		color: var(--color-title-alt);
	}

	.char-wrap {
		display: inline-block;
		position: relative;
		overflow: hidden;
	}

	.content__desc {
		grid-column: 2;
		line-height: 1;
		font-weight: 500;
		max-width: 550px;
		margin: 0;
		color: var(--color-desc);
	}

	.content__desc--cont {
		position: relative;
		color: var(--color-desc-cont);
	}

	.content__images {
		margin: 1.75rem 0 3rem 0;
	}

	.content__images .thumbgrid {
		height: auto;
		width: 100%;
		flex: none;
		aspect-ratio: 4 / 3;
		grid-template-columns: repeat(2, 50%);
		grid-template-rows: repeat(2, 50%);
		grid-area: thumbgrid;
	}

	.content__images .thumbgrid--content {
		grid-template-columns: 100%;
		grid-template-rows: 100%;
	}

	.thumbgrid--content .thumbgrid__item {
		grid-area: 1 / 1;
		aspect-ratio: 4 / 3;
	}

	.thumbgrid--content .thumbgrid__item-img {
		background-size: 100%;
		background-position: 0 0;
	}

	.thumb {
		height: 50vh;
		aspect-ratio: 4 / 3;
		width: auto;
		display: none;
	}

	.thumb--prev {
		grid-area: thumbprev;
		justify-self: end;
	}

	.thumb--next {
		grid-area: thumbnext;
	}

	.thumb__inner {
		width: 100%;
		height: 100%;
		background-size: cover;
		background-position: 50% 50%;
		filter: brightness(0.3);
	}

	.content__back {
		justify-self: start;
		grid-column: 2;
		color: #fff;
		text-transform: uppercase;
		padding: 0.15rem 0.85rem 0.25rem;
		border: 1px solid rgba(255, 255, 255, 0.3);
		border-radius: 2rem;
	}

	.page_title {
		margin-bottom: 8rem;
		text-decoration: underline;
		font-size: 8vh;
		text-align: center;
	}

	@media screen and (min-width: 53em) {
		.frame {
			position: fixed;
			height: 100vh;
			grid-template-columns: auto 1fr;
			grid-template-rows: auto auto;
			grid-template-areas: 'title sponsor' 'prev ...';
			align-content: space-between;
			justify-items: start;
			grid-gap: 2rem;
			padding: 0.75rem;
		}

		.menu__item {
			flex: 1;
			align-items: center;
			gap: 1.5rem;
			display: grid;
			grid-template-columns: 20vw auto auto 1fr;
		}

		.menu__item-title {
			font-size: clamp(1rem, 9vh, 5rem);
		}

		.menu__item-desc {
			display: block;
		}

		.content {
			padding: 1.5rem 0 1.5rem 0;
			align-content: end;
			/* display: grid; */
			column-gap: 2vw;
			grid-template-columns: 20vw 1fr;
			grid-template-rows: auto 12vw auto auto;
		}

		.content__images {
			display: grid;
			gap: 2vw;
			grid-template-columns: 20vw 1fr auto;
			grid-template-areas: 'thumbprev thumbgrid thumbnext';
		}

		.thumb {
			display: block;
		}

		.content__images .thumbgrid {
			height: 50vh;
			width: auto;
		}

		body #cdawrap {
			justify-self: end;
		}
	}

}