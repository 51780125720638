.navigation__container {
  nav {
    margin-top: 10px;
    /* position: fixed;
  z-index: 99; */
    /* Adjust the margin as needed */
  }

  .navbar {
    text-align: center;
    /* padding-top: 3rem; */
    display: flex;
    justify-content: space-between;
    padding: 1rem .5rem;
  }

  .active {
    visibility: hidden;
    ;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  li {
    /* display: inline-block; */
    margin-right: 10px;
    /* Adjust the margin as needed */
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  .about-navigation-link {
    position: relative;
    display: inline-block;
    color: #ffffff; /* Normal text color */
    text-decoration: none; /* Remove default underline */
    overflow: hidden; /* Ensures the pseudo-logo-navigation-link is contained within the link boundaries */
  }
  
  .about-navigation-link::after {
    content: '';
    position: absolute;
    left: 0;
    right: 100%; /* Start with the pseudo-logo-navigation-link fully to the right */
    bottom: 0;
    border-bottom: 1px solid #ffffff; /* This is the underline */
    transition: right 0.5s ease-out; /* Animate the right property */
  }
  
  .about-navigation-link:hover::after {
    right: 0; /* On hover, extend the pseudo-logo-navigation-link to full width */
    color: #ffffff; /* Change text color */
  }
  
  .about-navigation-link:hover {
    color: #ffffff; /* Ensure the text color changes on hover */
  }  

  .about-navigation-link:active {
    color: #ffffff; /* Ensure the text color changes on hover */
    text-decoration: none;
  }  

  .animate-border {
    position: relative;
    display: inline-block; /* Or another suitable display property */
    padding: 10px;
    border: 1px solid transparent; /* Maintain layout with invisible border */
    overflow: hidden; /* Ensure pseudo-elements don't overflow the container */
  }
  
  .animate-border::before, .animate-border::after {
    content: '';
    position: absolute;
    background: white; /* Color of the animated border */
  }
  
  .animate-border::before {
    left: 0;
    right: 0;
    top: 0;
    height: 1px; /* Top border height */
    transform: scaleX(0);
    transition: transform 0.3s ease-in-out;
  }
  
  .animate-border::after {
    top: 0;
    bottom: 0;
    left: 0;
    width: 1px; /* Left border width */
    transform: scaleY(0);
    transition: transform 0.3s ease-in-out;
  }
  
  .animate-border:hover::before {
    transform: scaleX(1); /* Animate top border in */
  }
  
  .animate-border:hover::after {
    transform: scaleY(1); /* Animate left border in */
  }
  
  /* Additional pseudo-elements for bottom and right borders */
  .animate-border::before, .animate-border::after {
    content: '';
    position: absolute;
    background: white; /* Color of the animated border */
  }
  
  .animate-border::before {
    left: 0;
    right: 0;
    bottom: 0;
    height: 1px; /* Bottom border height */
    transform: scaleX(0);
    transition: transform 0.3s ease-in-out;
  }
  
  .animate-border::after {
    top: 0;
    bottom: 0;
    right: 0;
    width: 1px; /* Right border width */
    transform: scaleY(0);
    transition: transform 0.3s ease-in-out;
  }
  
  .animate-border:hover::before {
    transform: scaleX(1); /* Animate bottom border in */
  }
  
  .animate-border:hover::after {
    transform: scaleY(1); /* Animate right border in */
  }
  

  
}