.LogoBehindTheDecks-svg, 
.LogoBehindTheKizomba-svg {
    text, rect {
        fill: white;
    }

    rect {
        stroke: none;
    }
}

.LogoBehindTheDecks-svg {
    width: 70%;
    text-align: center;
}

.LogoBehindTheDecks-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 6rem 0;
}